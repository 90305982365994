import CompanyRepository from "../repositories/CompanyRepository"
import AdminRepository from "../repositories/AdminRepository"
import AccessControlRepository from "@/repositories/AccessControlRepository"
import AuthLogic from "@/logics/AuthLogic"

export default class CompanyLogic {
  static async saveCompany(company) {
    if (company.uuid) {
      return this.updateCompany(company)
    }

    return this.createNewCompanyWithAdmin(company)
  }

  static async createNewCompanyWithAdmin(company) {
    const response = await AdminRepository.createCompany(
      JSON.stringify(company)
    )

    return response.responseObject().data
  }

  // admin deletion (!! different from normal company deletion)
  static async deleteCompany(company) {
    if (company.uuid) {
      await AdminRepository.deleteCompany(company.uuid)
      return true
    }
  }

  /** @description create or update company depending on id value */
  static async updateCompany(company) {
    if (company.uuid) {
      const attributes = JSON.stringify(company)

      const response = await CompanyRepository.patchOne(
        company.uuid,
        attributes
      )
      return response.responseObject().data
    } else {
      throw new Error(`invalid company format`)
    }
  }

  static async getOne(companyUuid, params = {}) {
    const response = await CompanyRepository.getOne(companyUuid, params)
    return response.responseObject().data
  }

  static async getAllCompanies() {
    const response = await AdminRepository.getAllCompanies()
    let companyList = response.responseObject()
    return companyList.data
  }

  static async getAllCompaniesWithCreditConsumption() {
    const response = await AdminRepository.getAllCompaniesWithCreditConsumption()
    let companyList = response.responseObject()
    return companyList.data
  }

  // --------------- Access Control  ---------------
  static async getCompanyRoles(companyUuid) {
    const response = await CompanyRepository.getCompanyRoles(companyUuid)
    let list = response.responseObject()
    return list.data
  }

  static async getUserCompanyRoles(companyUuid, userUuid) {
    if (companyUuid && userUuid) {
      const response = await CompanyRepository.getUserCompanyRoles(
        companyUuid,
        userUuid
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async addUserRoles(roleIds, roleSlugs, companyUuid, uuid) {
    const response = await CompanyRepository.addUserRoles(
      roleIds,
      roleSlugs,
      companyUuid,
      uuid
    )
    await AuthLogic.refreshRoles()
    return response.responseObject().data
  }

  static async deleteUserRoles(roleIds, roleSlugs, companyUuid, uuid) {
    const response = await CompanyRepository.deleteUserRoles(
      roleIds,
      roleSlugs,
      companyUuid,
      uuid
    )
    await AuthLogic.refreshRoles()
    return response.responseObject().data
  }

  // --------------- Users  ---------------
  static async getCompanyUsers(companyUuid, selectedRoles) {
    if (companyUuid) {
      const response = await CompanyRepository.getCompanyUsers(
        companyUuid,
        selectedRoles
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async saveCompanyUser(companyUuid, user) {
    if (user && user.uuid) {
      const response = await CompanyRepository.updateUser(companyUuid, user)
      return response.responseObject().data
    } else if (user) {
      const response = await CompanyRepository.createNewUser(companyUuid, user)
      return response.responseObject().data
    }
  }

  static async notifyCompanyUsers(companyUuid, body) {
    if (companyUuid) {
      const response = await CompanyRepository.notifyCompanyUsers(
        companyUuid,
        body
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async getEmployeeHierarchy(companyUuid, userUuid) {
    if (companyUuid && userUuid) {
      const response = await CompanyRepository.getEmployeeHierarchy(
        companyUuid,
        userUuid
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async deleteCompanyUser(companyUuid, user) {
    if (user && user.uuid) {
      const response = await CompanyRepository.deleteUser(companyUuid, user)
      return response.responseObject().data
    }
  }

  // --------------- Employees ---------------
  static async getAllEmployees(companyUuid, params = {}) {
    if (companyUuid) {
      const response = await CompanyRepository.getAllEmployees(companyUuid, params)
      const list = response.responseObject()
      return list.data
    }
  }

  static async pushNewEmployees(companyUuid, employeeList) {
    if (companyUuid) {
      let response = await CompanyRepository.createNewEmployees(
        companyUuid,
        employeeList
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async deleteEmployee(companyUuid, id) {
    if (companyUuid && id) {
      const response = await CompanyRepository.deleteOneEmployee(
        companyUuid,
        id
      )
      return response.responseObject()
    }
  }

  static async deactivateEmployee(companyUuid, employee) {
    if (companyUuid) {
      const response = await CompanyRepository.deactivateOneEmployee(
        companyUuid,
        employee
      )
      return response.responseObject()
    }
  }

  static async activateEmployee(companyUuid, employee) {
    if (companyUuid) {
      const response = await CompanyRepository.activateOneEmployee(
        companyUuid,
        employee
      )
      return response.responseObject()
    }
  }

  static async getEmployeeFilters(companyUuid) {
    if (companyUuid) {
      const response = await CompanyRepository.getEmployeeFilters(companyUuid)
      let list = response.responseObject()
      return list.data
    }
  }

  // --------------- Managing groups of employees ---------------

  static async getAllEmployeeGroups(companyUuid) {
    if (companyUuid) {
      const response = await CompanyRepository.getAllEmployeeGroups(companyUuid)
      let list = response.responseObject()
      return list.data
    }
  }

  static async pushEmployeeGroup(companyUuid, group) {
    if (companyUuid) {
      let response
      if (group.id) {
        response = await CompanyRepository.patchOneEmployeeGroup(
          companyUuid,
          group
        )
      } else {
        response = await CompanyRepository.createOneEmployeeGroup(
          companyUuid,
          group
        )
      }

      let list = response.responseObject()
      return list.data
    }
  }

  static async deleteEmployeeGroup(companyUuid, groupId) {
    if (companyUuid && groupId) {
      const response = await CompanyRepository.deleteOneEmployeeGroup(
        companyUuid,
        groupId
      )
      return response.responseObject()
    }
  }

  static async createOneSmartEmployeeGroup(companyUuid, rules) {
    if (companyUuid) {
      const response = await CompanyRepository.createOneSmartEmployeeGroup(
        companyUuid,
        rules
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async createMultipleEmployeeGroup(companyUuid, rules) {
    if (companyUuid) {
      const response = await CompanyRepository.createMultipleEmployeeGroup(
        companyUuid,
        rules
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async updateOneSmartEmployeeGroup(companyUuid, groupId, rules) {
    if (companyUuid && groupId) {
      const response = await CompanyRepository.updateOneSmartEmployeeGroup(
        companyUuid,
        groupId,
        rules
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async refreshOneSmartEmployeeGroup(companyUuid, group) {
    if (companyUuid && group) {
      const response = await CompanyRepository.refreshOneSmartEmployeeGroup(
        companyUuid,
        group
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async deleteOneEmployeeFromOneSmartEmployeeGroup(
    companyUuid,
    groupId,
    employeeId
  ) {
    if (companyUuid && groupId && employeeId) {
      const response =
        await CompanyRepository.deleteOneEmployeeFromOneSmartEmployeeGroup(
          companyUuid,
          groupId,
          employeeId
        )
      let list = response.responseObject()
      return list.data
    }
  }

  // --------------- Management of a single employee ---------------

  static async pushOneNewEmployee(companyUuid, employee) {
    if (companyUuid) {
      let response = await CompanyRepository.createOneNewEmployee(
        companyUuid,
        employee
      )
      let result = response.responseObject()
      return result.data
    }
  }

  static async editOneEmployee(companyUuid, employee) {
    if (companyUuid) {
      let response = await CompanyRepository.updateOneEmployee(
        companyUuid,
        employee
      )
      let result = response.responseObject()
      return result.data
    }
  }

  static async convertUserToEmployee(companyUuid, user) {
    if (companyUuid) {
      let response = await CompanyRepository.convertUserToEmployee(
        companyUuid,
        user
      )
      let result = response.responseObject()
      return result.data
    }
  }

  // --------------- Programs ---------------

  static async getAllPrograms(companyUuid) {
    if (companyUuid) {
      const response = await CompanyRepository.getAllPrograms(companyUuid)
      let list = response.responseObject()
      return list.data.programs
    }
  }

  static async getOneProgram(companyUuid, programId) {
    if (companyUuid && programId) {
      return await CompanyRepository.getOneProgram(companyUuid, programId)
    }
  }

  static async pushProgram(companyUuid, program) {
    if (companyUuid) {
      let response
      if (program.id) {
        response = await CompanyRepository.patchOneProgram(companyUuid, program)
      } else {
        response = await CompanyRepository.createOneProgram(
          companyUuid,
          program
        )
      }

      let list = response.responseObject()
      return list.data
    }
  }

  static async deleteProgram(companyUuid, programId) {
    if (companyUuid && programId) {
      const response = await CompanyRepository.deleteOneProgram(
        companyUuid,
        programId
      )
      return response.responseObject()
    }
  }

  // --------------- Campaigns ---------------

  static async getAllCampaigns(companyUuid) {
    if (companyUuid) {
      const response = await CompanyRepository.getAllCampaigns(companyUuid)
      let list = response.responseObject()
      return list.data.campaigns
    }
  }

  static async pushCampaign(companyUuid, campaign) {
    if (companyUuid) {
      let response
      if (campaign.id) {
        response = await CompanyRepository.patchOneCampaign(
          companyUuid,
          campaign
        )
      } else {
        response = await CompanyRepository.createOneCampaign(
          companyUuid,
          campaign
        )
      }

      let list = response.responseObject()
      return list.data
    }
  }

  static async deleteCampaign(companyUuid, campaignId) {
    if (companyUuid && campaignId) {
      const response = await CompanyRepository.deleteOneCampaign(
        companyUuid,
        campaignId
      )
      return response.responseObject()
    }
  }

  static async generateCampaignReport(
    companyUuid,
    campaignId,
    lang = "fr",
    typeOfReport = ""
  ) {
    if (!companyUuid || !campaignId) return

    const body = {
      lang: lang,
      campaignId: campaignId,
      reportType: typeOfReport,
    }

    const response = await CompanyRepository.generateCampaignReport(
      companyUuid,
      body
    )
    let list = response.responseObject()
    return list.data
  }

  // --------------- Emails ---------------
  static async getAvailableEmailTypes(companyUuid,lang) {
    if (companyUuid) {
      const response = await CompanyRepository.getAvailableEmailTypes(
        companyUuid,
        lang
      )
      let list = response.responseObject()
      return list.data
    }
  }
  static async getEmailTypes(companyUuid, emailType, lang, hatcourseId) {
    if (companyUuid && emailType && lang && hatcourseId) {
      const response = await CompanyRepository.getEmailTypes(
        companyUuid,
        emailType,
        lang,
        hatcourseId
      )
      let list = response.responseObject()
      return list.data
    }
  }

  // --------------- Phishing entities ---------------
  static async getCompanyPhishingEntities(companyUuid, path) {
    if (companyUuid && path) {
      const response = await CompanyRepository.getCompanyPhishingEntities(
        companyUuid,
        path
      )
      let list = response.responseObject()
      return list.data
    }
  }

  static async getCompanyExerciseTemplateTagsList(companyUuid, exerciseId) {
    if (companyUuid && exerciseId) {
      const response =
        await CompanyRepository.getCompanyExerciseTemplateTagsList(
          companyUuid,
          exerciseId
        )
      let list = response.responseObject()
      return list.data
    }
  }

  static async getCompanyExerciseCompiledTemplates(companyUuid, exerciseId) {
    if (companyUuid && exerciseId) {
      const response =
        await CompanyRepository.getCompanyExerciseCompiledTemplates(
          companyUuid,
          exerciseId
        )
      let list = response.responseObject()
      return list.data
    }
  }

  static async getCompanyExerciseCompiledWithTemplateTags(
    companyUuid,
    exerciseId,
    tags
  ) {
    if (companyUuid && exerciseId) {
      const response =
        await CompanyRepository.getCompanyExerciseCompiledWithTemplateTags(
          companyUuid,
          exerciseId,
          tags
        )
      let list = response.responseObject()
      return list.data
    }
  }

  //------------------------ Program Presets ------------------------//
  static async getAllProgramPresets(companyUuid) {
    if (companyUuid) {
      const response = await CompanyRepository.getAllProgramPresets(companyUuid)
      let list = response.responseObject()
      return list.data
    }
  }

  static async getOneProgramPreset(companyUuid, presetId) {
    if (companyUuid && presetId) {
      const response = await CompanyRepository.getOneProgramPreset(
        companyUuid,
        presetId
      )
      return response.responseObject().data
    }
  }

  static async instantiateOneProgramPreset(companyUuid, presetId, body) {
    if (companyUuid && presetId) {
      const response = await CompanyRepository.instantiateOneProgramPreset(
        companyUuid,
        presetId,
        body
      )
      return response.responseObject().data
    }
  }

  // --------------- Azure ---------------
  static async getAllUsersAzure(companyUuid, body) {
    if (companyUuid) {
      const response = await CompanyRepository.getAllUsersAzure(
        companyUuid,
        body
      )
      return response.responseObject().data
    }
  }

  static async getConfigurationSsoAzure(companyUuid, type) {
    if (companyUuid) {
      const response = await CompanyRepository.getConfigurationSsoAzure(
        companyUuid,
        type
      )
      return response.responseObject().data
    }
  }

  static async createConfigurationSsoAzure(companyUuid, body) {
    if (companyUuid) {
      const response = await CompanyRepository.createConfigurationSsoAzure(
        companyUuid,
        body
      )
      return response.responseObject().data
    }
  }

  static async storeConfigurationSsoAzure(companyUuid, body) {
    if (companyUuid) {
      const response = await CompanyRepository.storeConfigurationSsoAzure(
        companyUuid,
        body
      )
      return response.responseObject()
    }
  }

  static async destroyConfigurationSsoAzure(companyUuid, type) {
    if (companyUuid) {
      const response = await CompanyRepository.destroyConfigurationSsoAzure(
        companyUuid,
        type
      )
      return response.responseObject()
    }
  }

  //-------------- Admins ---------------//
  static async reComputeStoppedStats(companyUuid) {
    const response = await CompanyRepository.singleAdminAction(companyUuid, "recompute-stopped-stats")
    return response.responseObject().data
  }

  //-------------- Gamification ---------------//
  static async refreshGamification(companyUuid) {
    const response = await CompanyRepository.singleAdminAction(
      companyUuid,
      "gamification/refresh",
    )
    return response.responseObject().data
  }

  static async getEmployeeGamificationProfile(companyUuid, employeeId) {
    const response = await CompanyRepository.singleAdminAction(
      companyUuid,
      "gamification/employees/" + employeeId + "/profile",
    )
    return response.responseObject().data
  }


  //-------------- Domains ---------------//

  static async saveDomain(companyUuid, domainName, domainId) {
    if (domainId) {
      const response = await CompanyRepository.updateDomain(
        companyUuid,
        domainName,
        domainId
      )
      return response.responseObject().data
    } else {
      const response = await CompanyRepository.createDomain(
        companyUuid,
        domainName
      )
      return response.responseObject().data
    }
  }

  static async getAllDomains(companyUuid) {
    const response = await CompanyRepository.getAllDomains(companyUuid)
    let domainList = response.responseObject()
    return domainList.data
  }

  static async deleteDomain(companyUuid, domainId) {
    const response = await CompanyRepository.deleteOneDomain(
      companyUuid,
      domainId
    )
    return response.responseObject()
  }

  static async checkDomain(companyUuid, domainId) {
    const response = await CompanyRepository.checkOneDomain(
      companyUuid,
      domainId
    )
    return response.responseObject()
  }

  // ------------- Customization --------------//
  static async getCustomization(companyUuid = null, withoutDefaultCustomization = true) {
    const response = await CompanyRepository.getCustomization(
      companyUuid,
      withoutDefaultCustomization
    )

    return response.responseObject().data
  }

  static async recomputeOppensLearningUsersConnectionLinks(companyUuid) {
    const response = await CompanyRepository.singleAdminAction(
      companyUuid,
      "recompute-oppens-learning-users-connection-links"
    )
    return response.responseObject().data
  }

  static async refreshOppensLearningUsers(companyUuid) {
    const response = await CompanyRepository.singleAdminAction(
      companyUuid,
      "refresh-oppens-learning-users"
    )
    return response.responseObject().data
  }
  // ------------- CreditConsumption --------------//
    static async getSubscription(companyUuid) {
    const response = await CompanyRepository.getSubscription(
      companyUuid,
    )
    return response.responseObject().data
    }
    static async getBalance(companyUuid) {
    const response = await CompanyRepository.getBalance(
      companyUuid,
    )
    return response.responseObject().data
    }
    static async getCreditConsumptionHistory(companyUuid) {
    const response = await CompanyRepository.getCreditConsumptionHistory(
      companyUuid,
    )
    return response.responseObject().data
    }
    static async getDetailedCreditConsumptionHistory(companyUuid,year,month) {
    const response = await CompanyRepository.getDetailedCreditConsumptionHistory(
      companyUuid,
      year,
      month
    )
    return response.responseObject().data
    }
  // ------------- GetEmployeeListCsv --------------//

  static async getEmployeeList(companyUuid) {
        try {
      const res = await CompanyRepository.getEmployeeList(
        companyUuid,
      )
      return res
    } catch (e) {
      return e.message
    }
  }

}
