<script>
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "company-azure-ad-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      syncUsersAzureAd: false,
      listEmployeesAzure: null,
      loadingEmployeesAzure: false,
      is_synchronization_active: false,
      headers: [
        {
          text: "Email",
          align: "start",
          sortable: true,
          filterable: true,
          value: "email",
        },
        {
          text: "displayName",
          align: "start",
          sortable: true,
          filterable: true,
          value: "displayName",
        },
        {
          text: "givenName",
          align: "start",
          sortable: true,
          filterable: true,
          value: "givenName",
        },
        {
          text: "surname",
          align: "start",
          sortable: true,
          filterable: true,
          value: "surname",
        },
        {
          text: "country",
          align: "start",
          sortable: true,
          filterable: true,
          value: "country",
        },
      ],
      footers: {
        "items-per-page-options": [10, 30, 50, 100, 200, -1],
      },
    }
  },
  async mounted() {
    this.init(this.data)
    this.is_synchronization_active = this.localData.active_directory_credentials.active
  },
  watch: {
    data(newValue, oldValue) {
      this.localData = { ...newValue }
    },
    syncUsersAzureAd: function (val) {
      this.localData.active_directory_credentials.active = val
      this.is_synchronization_active = this.localData.active_directory_credentials.active
    },
  },
  methods: {
    init(companyData) {
      // TODO call the backend to get the Active Directory credentials - should be inited there when necessary
      const localData = { ...companyData }

      // if credentials are null or empty
      if (
        !localData.active_directory_credentials ||
        (Array.isArray(localData.active_directory_credentials) &&
          localData.active_directory_credentials.length === 0)
      ) {
        localData.active_directory_credentials = this.defaultStructure()
      } else if (localData.active_directory_credentials.active) {
        this.syncUsersAzureAd = true
      }

      this.localData = localData
    },
    defaultStructure() {
      return {
        client_id: "",
        tenant_id: "",
        client_secret: "",
        filters: {
          domains: "",
          countries: "",
          ignoreWords: {
            displayName: "",
            givenName: "",
            surname: "",
          },
        },
      }
    },
    async testImportUsersAzure() {
      this.listEmployeesAzure = null
      this.loadingEmployeesAzure = true

      let employees = await CompanyLogic.getAllUsersAzure(
        this.localData.uuid,
        this.localData.active_directory_credentials.filters
      )

      if (employees && employees.length > 0) {
        this.listEmployeesAzure = employees.map((employee) => ({
          country: employee.country,
          displayName: employee.name,
          email: employee.email,
          givenName: employee.first_name,
          surname: employee.last_name,
        }))
      }

      this.loadingEmployeesAzure = false
    },
    async validate() {
      return true
    },
    getCompanyData() {
      return {
        active_directory_credentials:
          this.localData.active_directory_credentials,
      }
    },
  },
}
</script>

<template>
  <v-container
    fluid
    style="width: 100%; height: 100%; padding: 0"
    :style="{ backgroundColor: '#ffffff' }"
  >
    <div
      class="flex-container"
      v-if="localData && localData.active_directory_credentials"
    >
      <div class="bloc" style="margin-right: 5px">
        <v-row>
          <v-col cols="12">
            <div class="bloc-title">
              {{
                $t(
                  "profile.active_directory_credentials.azure_ad_sync_parameters"
                )
              }}
            </div>
          </v-col>

          <v-col cols="12">
            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="clientId"
                color="green"
                :label="$t('profile.active_directory_credentials.client_id')"
                v-model="localData.active_directory_credentials.client_id"
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="tenantId"
                color="green"
                :label="$t('profile.active_directory_credentials.tenant_id')"
                v-model="localData.active_directory_credentials.tenant_id"
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                color="green"
                name="clientSecret"
                :label="
                  $t('profile.active_directory_credentials.client_secret')
                "
                v-model="localData.active_directory_credentials.client_secret"
              >
              </op-text-field>
            </v-card-text>
          </v-col>

          <v-col cols="12">
            {{ $t("profile.active_directory_credentials.managing_user_sync") }}
            <v-switch
              v-model="syncUsersAzureAd"
              flat
              :label="$t('profile.active_directory_credentials.activate_sync')"
              color="green"
            ></v-switch>
          </v-col>
        </v-row>
      </div>

      <div class="bloc" style="margin-left: 5px">
        <v-row>
          <v-col cols="12">
            <div class="bloc-title">
              {{
                $t(
                  "profile.active_directory_credentials.filter_azure_ad_results"
                )
              }}
            </div>
          </v-col>

          <v-col cols="12">
            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="filters_domains"
                type="text"
                rules=""
                color="green"
                v-model="localData.active_directory_credentials.filters.domains"
                :label="
                  $t(
                    'The domain names to be included (separated by a semicolon)'
                  )
                "
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="filters_countries"
                type="text"
                rules=""
                color="green"
                v-model="
                  localData.active_directory_credentials.filters.countries
                "
                :label="
                  $t(
                    'The country names to be included (separated by a semicolon)'
                  )
                "
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="filters_ignore_words_display_name"
                type="text"
                rules=""
                color="green"
                v-model="
                  localData.active_directory_credentials.filters.ignoreWords
                    .displayName
                "
                :label="
                  $t(
                    'Words to exclude from the displayName field (separated by a semicolon)'
                  )
                "
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="filters_ignore_words_given_name"
                type="text"
                rules=""
                color="green"
                v-model="
                  localData.active_directory_credentials.filters.ignoreWords
                    .givenName
                "
                :label="
                  $t(
                    'Words to exclude from the givenName field (separated by a semicolon)'
                  )
                "
              >
              </op-text-field>
            </v-card-text>

            <v-card-text class="px-3 pa-0">
              <op-text-field
                name="surname"
                type="text"
                rules=""
                color="green"
                v-model="
                  localData.active_directory_credentials.filters.ignoreWords
                    .surname
                "
                :label="
                  $t(
                    'Words to exclude from the surname field (separated by a semicolon)'
                  )
                "
              >
              </op-text-field>
            </v-card-text>

            <div class="px-3 pa-0">
              <v-btn
                elevation="2"
                @click.stop="
                  !localData.active_directory_credentials.client_id ||
                  !localData.active_directory_credentials.tenant_id ||
                  !localData.active_directory_credentials.client_secret
                    ? null
                    : testImportUsersAzure()
                "
                dark
                :color="
                  !localData.active_directory_credentials.client_id ||
                  !localData.active_directory_credentials.tenant_id ||
                  !localData.active_directory_credentials.client_secret
                    ? 'grey'
                    : 'green'
                "
                class="text-none rounded-lg mr-3"
              >
                {{ $t("view.button.test_azure_filter") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-card-text>
      <v-progress-linear
        v-if="loadingEmployeesAzure"
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
      <div class="d-block" v-if="listEmployeesAzure">
        <div class="bloc-large">
          <div class="bloc-title">
            <b>
              {{ listEmployeesAzure.length }}
              {{
                $t("profile.active_directory_credentials.users_from_directory")
              }}
            </b>
          </div>
          <v-card class="ma-3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="  Recherche"
              single-line
              hide-details
              background-color="#def9f4"
              class="ma-2"
            >
            </v-text-field>
            <br />
            <v-data-table
              :headers="headers"
              :items="listEmployeesAzure"
              :items-per-page="10"
              class="elevation-1"
              :search="search"
              :footer-props="footers"
              :item-class="itemRowBackground"
            >
              <template v-slot:no-data>Aucun employé</template>
            </v-data-table>
          </v-card>
        </div>
      </div>

      <!--div v-else-if="loadingEmployeesAzure" class="d-flex align-center justify-center">
        <div class="loading"></div>
      </div-->
    </v-card-text>
  </v-container>
</template>

<style scoped>
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bloc {
  border: 1px solid #efefef;
  border-radius: 5px;
  flex: 1 1 auto;
  width: 250px;
  padding: 20px;
  margin: 10px;
}
.bloc-title {
  font-weight: bold !important;
  text-align: center;
  font-size: 1.5em;
  color: #2a2929;
}

.loading {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #1cc8aa;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
</style>
