<template>
  <div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>

    <v-card elevation="0" outlined v-if="data">
      <v-expansion-panels v-model="openPanel">
        <v-expansion-panel @click="handlePanelClick">
          <v-expansion-panel-header class="oppens-pannel-header">
            <v-row style="display: flex;justify-content: space-between;">
              <v-col cols="5" md="5" sm="12">
                <div class="d-flex flex-column">
                  <div>
                    <!-- icon and title -->
                    <v-icon class="mr-1" color="#000">mdi-hook</v-icon>
                    <body-2 class="d-inline-block text-truncate"
                      >{{ data.name }}
                    </body-2>
                  </div>
                  <div class="pl-5">
                    <!-- launch date (under the title) -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span
                          v-on="on"
                          class="mr-2 text-no-wrap small-subtitle"
                        >
                          {{ formatDate(data.launch_date) }}
                        </span>
                      </template>
                      <span class="small-subtitle">{{
                        $t("view.companies.start_of_shipments")
                      }}</span>
                    </v-tooltip>

                    <!-- ..... -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span class="text-no-wrap small-subtitle-2" v-on="on"
                          >{{
                            getBaseUrl(
                              data.phishing_exercise.phishing_server.url
                            )
                          }}
                        </span>
                      </template>
                      <span>{{ $t("view.learning_phishing.server") }}</span>
                    </v-tooltip>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </v-col>

              <v-col cols="2" md="3" sm="12" class="d-flex align-center">
                <img
                  class="header-icon me-2 custom-small-img"
                  :src="getHeaderImage('ClickedLinks')"
                  v-if="percent.clickedLink"
                />
                <v-tooltip top v-if="percent.clickedLink">
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                      style="text-transform: lowercase"
                      class="me-8"
                    >
                      {{ percent.clickedLink }}%
                    </span>
                  </template>
                  <span>{{ $t("view.dashboard.clicked_link") }}</span>
                </v-tooltip>
                <img
                  class="header-icon me-2 custom-small-img"
                  :src="getHeaderImage('EnteredData')"
                  v-if="percent.submittedData"
                />
                <v-tooltip top v-if="percent.submittedData">
                  <template v-slot:activator="{ on }">
                    <span v-on="on" style="text-transform: lowercase">
                      {{ percent.submittedData }}%
                    </span>
                  </template>
                  <span>{{ $t("view.dashboard.submited_data") }}</span>
                </v-tooltip>
              </v-col>

              <v-col
                cols="2"
                md="2"
                sm="2"
                class="d-flex justify-end align-center"
              >
                <!-- Error picto -->
                <div class="warning-container">
                  <v-icon
                    v-if="data.errors && !localModeLine"
                    color="#FF5252"
                    class="warning-icon"
                    >mdi-alert</v-icon
                  >
                  <span
                    v-if="data.errors && !localModeLine"
                    class="warning-message"
                    >{{ data.errors }}&nbsp;{{ $t("view.dashboard.errors") }}</span
                  >
                </div>
                <!-- Test (chip) -->
                <v-chip
                  small
                  label
                  dark
                  color="#198bed"
                  class="mr-1 text-capitalize"
                  >{{ data.is_test ? "Test" : "Normal" }}
                </v-chip>

                <!-- Active (chip) -->
                <v-chip
                  small
                  v-if="data.is_active"
                  dark
                  color="#1ac8aa"
                  class="mr-1"
                  >{{ $t("view.companies.active") }}
                </v-chip>

                <!-- Stopped (chip) -->
                <v-chip
                  small
                  v-if="data.is_stopped"
                  dark
                  color="red"
                  class="mr-1"
                  >{{ $t("view.companies.stopped") }}
                </v-chip>

                <!-- Automatic Launch (chip) -->
                <v-icon
                  v-if="data.has_automatic_launch"
                  class="mr-1"
                  elevation="2"
                  icon
                  >mdi-timelapse
                </v-icon>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content
            class="panel-content-border oppens-pannel-content"
          >
            <v-row>
              <v-col cols="12">
                <b class="mb-2"> {{ $t("view.companies.automation") }} : </b>
                <op-automation-component :entity="data">
                </op-automation-component>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <b>{{ $t("view.companies.informations") }} :</b>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.companies.exercise") }} :</span
                  ><span class="mr-4">{{ data.phishing_exercise.title }} </span>
                  <v-chip
                    :to="'/phishing-exercises/' + data.phishing_exercise.id"
                    small
                    label
                    outlined
                    dark
                    color="blue"
                    v-if="isPhishingSystemAdmin"
                  >
                    {{ data.phishing_exercise.id }}
                  </v-chip>
                </div>

                <div cols="12">
                  <span class="subtitle"
                    >{{ $t("view.learning_phishing.themes") }} :</span
                  >
                  <span>{{ data.phishing_exercise.theme }}</span>
                </div>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.learning_phishing.server") }} :</span
                  >
                  <span>{{
                    getBaseUrl(data.phishing_exercise.phishing_server.url)
                  }}</span>
                </div>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.learning_phishing.sender") }} :</span
                  >
                  <span class="mr-4">{{ data.from_address }} </span>
                </div>

                <div>
                  <span class="subtitle"
                    >{{ $t("view.dashboard.internal_references") }} :</span
                  >
                  <span v-if="data.id" class="pl-1">{{ data.id }}</span>
                  <span v-if="data.gophish_campaign_id">
                    . {{ data.gophish_campaign_id }}</span
                  >
                </div>
                <div>
                  <span class="subtitle"
                    >{{ $t("view.companies.start_of_shipments") }} :</span
                  ><span
                    ><i> {{ formatLaunchDate(data) }}</i></span
                  >
                </div>

                <div>
                  <span class="subtitle">
                    {{ $t("view.companies.end_of_shipments") }} :</span
                  ><span
                    ><i
                      >{{ data.send_by_date }} {{ data.send_by_time }}</i
                    ></span
                  >
                </div>
                <div>
                  <span class="subtitle"
                    >{{ $t("view.companies.nb_employees") }} :</span
                  ><span>{{ data.nb_employees }}</span>
                </div>
              </v-col>
            </v-row>

            <v-card-text>
              <div v-if="employees && revealEmployees">
                <v-data-table
                  :multi-sort="true"
                  :items="employees"
                  :server-items-length="totalEmployees"
                  @update:options="changeListOptions"
                  :page.sync="currentPage"
                  :headers="shortHeadersv2"
                  dense
                  fixed-header
                  :footer-props="{
                    'items-per-page-options': itemsPerPageOptions,
                  }"
                  :items-per-page="itemsPerPage"
                  :loading="employeeTableIsLoading"
                  class="px-4"
                >
                </v-data-table>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                elevation="2"
                @click="createTest()"
                class="text-none rounded-lg"
                >{{ $t("view.companies.create_a_test") }}
              </v-btn>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="green"
                    class="ma-2"
                    @click="visualizeCompiledExercise()"
                    >mdi-eye-outline
                  </v-icon>
                </template>
                <span>{{ $t("view.button.preview") }}</span>
              </v-tooltip>

              <!--v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="green"
                    class="ma-2"
                    @click="toggleRevealEmployees()"
                    >mdi-account-group
                  </v-icon>
                </template>
                <span>{{ $t("view.dashboard.employees") }}</span>
              </v-tooltip-->

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="green"
                    @click="toggleResults()"
                    :disabled="
                      !data.gophish_campaign_id && !data.no_gophish_server
                    "
                    class="ma-2"
                    >mdi-trophy-outline
                  </v-icon>
                </template>
                <span>{{ $t("view.companies.results") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="green"
                    class="ma-2"
                    @click="edit()"
                    :disabled="data.is_stopped"
                    >mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t("Edit") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="green"
                    class="ma-1"
                    @click="duplicate()"
                    >mdi-content-copy
                  </v-icon>
                </template>
                <span>{{ $t("view.button.duplicate") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click="launch()"
                    color="green"
                    :disabled="data.is_active || data.is_stopped"
                    class="ma-2"
                  >
                    mdi-play-circle-outline
                  </v-icon>
                </template>
                <span>{{ $t("view.button.launch") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="red"
                    @click="stop()"
                    :disabled="data.is_stopped || !data.is_active"
                    class="ma-2"
                    >mdi-stop-circle-outline
                  </v-icon>
                </template>
                <span>{{ $t("view.button.stop") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    color="red"
                    @click="suppress()"
                    class="ma-2"
                    elevation="2"
                    icon
                    >mdi-delete
                  </v-icon>
                </template>
                <span>{{ $t("view.button.delete") }}</span>
              </v-tooltip>
            </v-card-actions>

            <v-expand-transition>
              <phishing-simulation-results
                v-if="revealResults"
                :simulation="data"
                :results="results"
                v-on:close="closeResults"
                :serverUrl="
                  getBaseUrl(data.phishing_exercise.phishing_server.url)
                "
              >
              </phishing-simulation-results>
            </v-expand-transition>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import employeeTableMixin from "@/mixins/employeeTable.mixin"
import phishingMixin from "@/mixins/phishing.mixin"
import PhishingLogic from "@/logics/PhishingLogic"
import AuthLogic from "@/logics/AuthLogic"
import monitoringMixins from "@/mixins/monitoring.mixins.js"
import ClickedLinks from "@/assets/clicked-links.svg"
import EnteredData from "@/assets/entred-data.svg"
import AuthStore from "@/stores/AuthStore"
import moment from "moment/moment"
import { saveAs } from "file-saver"

export default {
  mixins: [employeeTableMixin, phishingMixin, monitoringMixins],
  name: "phishing-simulation",
  props: {
    modeLine: {
      default: false,
    },
    data: {
      default: null,
    },
    companyUuid: {
      default: null,
    },
  },
  data: () => ({
    openPanel: null,
    snackbar: false,
    snackbarText: null,
    loading: false,
    localModeLine: false,
    reveal: false,
    revealEmployees: false,
    options: {},
    results: null,
    revealResults: false,
    status: null,
    totalMailSent: 0,
    clickedLink: 0,
    submittedData: 0,
    percent: {
      clickedLink: null,
      submittedData: null,
    },
  }),
  computed: {
    AuthLogic() {
      return AuthLogic
    },
  },

  created() {
    this.openPanel = this.modeLine ? null : 0
  },
  watch: {
    modeLine(newValue) {
      this.openPanel = newValue ? null : 0
    },
  },
  async mounted() {
    // Vérifiez si la simulation a un serveur de phishing
    if (
      this.data &&
      this.data.phishing_exercise &&
      this.data.phishing_exercise.phishing_server
    ) {
      // Récupérez le statut du serveur de phishing
      // this.monitoringRecord = await this.getServerStatus(
      //  this.data.phishing_exercise.phishing_server.id
      //)
      // Définissez status sur le statut récupéré
      if (this.monitoringRecord) {
        this.status = this.monitoringRecord.status
      }
    }

    if (this.data.stats) {
      this.parseStatistics(this.data.stats)
    }
  },
  methods: {
    formatDate(str) {
      return moment(str).format("MMMM D, YYYY")
    },

    isPhishingSystemAdmin() {
      return AuthStore.hasSystemRole("phishing-system-admin")
    },
    getHeaderImage(header) {
      switch (header) {
        case "EnteredData":
          return EnteredData
        case "ClickedLinks":
          return ClickedLinks
      }
    },
    //getStatus(status) {
    //const statusMap = {
    //  up: { icon: "mdi-check-circle", color: "green" },
    // down: {
    //   icon: "mdi-close-circle",
    //   color: "red",
    // },
    // unknown: { icon: "mdi-alert-circle-outline", color: "grey darken-3" },
    //}

    //return statusMap[status] || statusMap.unknown
    //},
    edit() {
      this.$emit("edit", this.data.id)
    },
    suppress() {
      this.$emit("suppress", this.data.id)
      this.reveal = false
    },

    close() {
      this.reveal = false
    },

    launch() {
      this.$emit("launch", this.data.id)
    },

    stop() {
      this.$emit("stop", this.data.id)
    },

    duplicate() {
      const clone = JSON.parse(JSON.stringify(this.data))
      this.$emit("duplicate", clone)
    },

    async toggleResultsFromPanelHeader() {
      //consider the results are hidden
      this.revealResults = false
      await this.toggleResults()
    },

    async toggleResults() {
      const revealResults = !this.revealResults

      if (revealResults) {
        this.makeSurePanelIsOpen()

        if (!this.results) {
          await this.getResults()
        }

        this.revealEmployees = false
      }

      this.revealResults = revealResults
    },

    makeSurePanelIsOpen() {
      if (null === this.openPanel) {
        this.openPanel = 0
      }
    },
    async getResults() {
      try {
        this.results = await this.getSimulationResults(this.data)
        this.totalMailSent = this.clickedLink = this.submittedData = 0
        if (this.results) {
          this.parseResults(this.results)
        }
      } catch (error) {
        console.error("Error fetching results:", error)
        this.totalMailSent = this.clickedLink = this.submittedData = 0
      }
    },

    parseResults(results) {
      this.stats = results.stats
      if (this.stats) {
        this.totalMailSent = this.stats.total || 0
        this.clickedLink = this.stats.clicked || 0
        this.submittedData = this.stats.submitted_data || 0
      }
    },

    parseStatistics(stats) {
      this.percent.clickedLink = stats.summary?.percentClicked ?? null
      this.percent.submittedData = stats.summary?.percentSubmitted ?? null
    },

    closeResults() {
      this.revealResults = false
    },

    async refreshEmployees(pageNumber) {
      if (!this.data.id || !this.companyUuid || this.employeeTableIsLoading) {
        return
      }

      try {
        this.employeeTableIsLoading = true

        let params = this.getParams(pageNumber)
        if (pageNumber) {
          this.currentPage = pageNumber
        }

        const result = await PhishingLogic.getEmployees(this.companyUuid, {
          ...params,
          ...this.sortOptions,
          "simulation_ids[]": this.data.id,
          with_simulation_test: 1,
          with_results: 0,
          with_summary: 0,
          with_campaign_company_courses: 0,
          with_admin_infos: 1,
        })

        this.employees = result.data.employees
        this.totalEmployees = result.meta.total
        this.filteringOptions = result.meta.filtering_options
      } catch (error) {
        //this.displayAlert(error?.response?.data?.error?.message)
        console.error(error?.response?.data?.error?.message)
      } finally {
        this.employeeTableIsLoading = false
      }
    },

    /**
     * DEPRECATED
    async toggleRevealEmployees() {
      const revealEmployees = !this.revealEmployees

      if (revealEmployees && (!this.employees || this.employees.length === 0)) {
        await this.refreshEmployees(1)
      }

      if (revealEmployees) {
        this.revealResults = false
      }

      this.revealEmployees = revealEmployees
    },*/

    formatLaunchDate(date) {
      return date.launch_date + " " + date.launch_time
    },

    async visualizeCompiledExercise() {
      if (!this.data.phishing_exercise_id) {
        console.error("no exercise selected")
        return
      }
      const templates = await this.getSimulationCompiledTemplates(
        this.companyUuid,
        this.data.id
      )

      this.$emit("view", templates)
    },

    async createTest() {
      this.$emit("createTest", this.data.id)
    },
    handlePanelClick() {},

    getBaseUrl(url) {
      // Removes the port number from the URL if present
      return url.replace(/:\d+$/, "")
    },
  },
}
</script>

<style lang="scss" scoped>
.subtitle {
  min-width: 250px;
  display: inline-block;
  color: #7a7a7a;
  font-size: 0.9rem;
}

.error-warning {
  background-color: red;
  color: white;
  padding: 1px 10px;
  display: inline-block;
  border-radius: 5px;
  font-size: 0.7rem;
}

.m-5 {
  margin: 5px;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.date-text {
  color: #7a7a7a;
  display: block;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 12px;
}

.custom-card {
  height: 80px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.custom-small-img {
  width: 2rem;
  height: 2rem;
}

.small-subtitle {
  font-size: 0.9rem;
  text-transform: capitalize;
  color: grey;
  padding-left: 10px;
}

.small-subtitle-2 {
  font-size: 0.8rem;
  color: grey;
}
.warning-container {
  position: relative;
  display: inline-block;
}

.warning-icon {
  margin-right: 5px;
  cursor: pointer;
}

.warning-message {
  display: none;
  position: absolute;
  bottom:0;
  right: 100%; 
  margin-left: 5px; 
  background-color: #FF5252;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: normal;
  word-wrap: break-word; 
  z-index: 10;
  font-size: 12px;
  max-width: 150px;
}

.warning-icon:hover + .warning-message {
  display: block;
}

</style>
