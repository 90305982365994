<template>
  <div v-if="simulation">
    <v-card elevation="0">
      <v-card-title class="d-flex justify-space-between">
        <div></div>
        <div>
          <v-icon @click="refreshData()">mdi-refresh</v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#summary"> {{ $t("view.companies.summary") }}</v-tab>
          <v-tab href="#timeline"> {{ $t("view.companies.timeline") }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="summary">
            <!-- summary -->
            <phishing-simulation-summary
              v-on:export="exportResults"
              :simulationIsActive="simulationIsActive"
              :results="simulationResults"
              :serverUrl="serverUrl"
              v-if="simulationResults"
            >
            </phishing-simulation-summary>
          </v-tab-item>

          <v-tab-item value="timeline">
            <!-- timeline -->
            <phishing-simulation-timeline
              :timeline="localTimeline"
              v-on:export="exportTimeline"
              v-if="localTimeline"
            >
            </phishing-simulation-timeline>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-expand-transition></v-expand-transition>
    </v-card>
  </div>
</template>
<script>
import phishingMixin from "@/mixins/phishing.mixin"
import PhishingLogic from "@/logics/PhishingLogic"
import { saveAs } from "file-saver"
import { i18n } from "@/plugins/i18n"

export default {
  name: "phishing-simulation-results",
  mixins: [phishingMixin],
  components: {},
  props: {
    simulation: {
      default: null,
    },
    results: {
      default: null,
    },
    serverUrl: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    tab: null,
    reveal: false,
    studentResults: null,
    simulationResults: null,
    sentLabels: [i18n.t("Emails sent")],
    localTimeline: null,
    simulationIsActive:""
  }),
  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
  },
  mounted() {
    this.simulationResults = JSON.parse(JSON.stringify(this.results))
    this.computeTimeline()
    this.concactSimulationStatus()
  },
  methods: {
    concactSimulationStatus() {
      if (this.simulation.is_active && !this.simulation.is_stopped) {
        return this.simulationIsActive = true
      } else
      return this.simulationIsActive = false
    },
    async refreshData() {
      this.simulationResults = await this.fetchSimulationResults(
        this.simulation.id
      )
      this.computeTimeline()
    },
    computeTimeline() {
      const results = this.simulationResults

      if (!results || !results.results) {
        console.error("no results.results")
        return
      }

      if (results.results.timeline) {
        this.localTimeline = JSON.parse(
          JSON.stringify(results.results.timeline)
        )
      }
    },
    close() {
      this.$emit("close")
    },
    async exportResults() {
      let res = await PhishingLogic.export(
        this.companyUuid,
        this.simulation.id,
        "results"
      )

      let fileName = `${this.simulation.name}-results.csv`
      saveAs(res.data, fileName)
    },
    async exportTimeline() {
      let res = await PhishingLogic.export(
        this.companyUuid,
        this.simulation.id,
        "timeline"
      )
      let fileName = `${this.simulation.name}-timeline.csv`
      saveAs(res.data, fileName)
    },
  },
}
</script>
<style lang="scss" scoped></style>
