import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
import AuthLogic from "@/logics/AuthLogic"
import AuthStore from "@/stores/AuthStore"
import { showSnackbar } from "../services/GlobalActions"

Vue.use(VueRouter)

const noUserNeededRoutesNames = ["login"]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const accessToken = AuthLogic.getTokens()
  const needs2FA = AuthLogic.get2FAIsNeeded()

  if (to.fullPath.substr(-1) === "/" && to.fullPath.length > 1) {
    const path = to.fullPath.slice(0, -1)
    next(path)
  }

  if (accessToken && !needs2FA) {
    if (!AuthStore.me) {
      await AuthLogic.loadMe()
    }

    const role = AuthStore.me.role
    let autorised = true

    if (to.matched) {
      const auth = true
      next()
      /*
      for (const matchedRoute of to.matched) {
        if (
          matchedRoute.meta &&
          matchedRoute.meta.allowedRoles &&
          !matchedRoute.meta.allowedRoles.includes(role)
        ) {
          console.error('not allowed to access this page', matchedRoute.meta.allowedRoles)
          autorised = false
          break
        }
      }

      if (autorised) {
        next()
      } else {
        showSnackbar(
          "Vous n'avez pas les droits suffisants pour accéder à cette page"
        )
        next({
          path: "dashboard",
        })
      }*/
    }

    next()
  } else {
    if (noUserNeededRoutesNames.includes(to.name)) {
      next()
    } else {
      next({
        path: "/login",
      })
    }
  }
})

export default router
