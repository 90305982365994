<template>
  <div>
    <v-menu
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="localDate"
          :label="label"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          clearable
          :readonly="simulationIsActive"
        ></v-text-field>
      </template>
      <v-date-picker
        v-if="!simulationIsActive"
        v-model="localDate"
        @input="menu2 = false"
        :max="computedMaxDate"
        :min="computedMinDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "op-datepicker",
  props: {
    date: {
      default: new Date(),
    },
    label: {
      default: "date",
    },
    simulationIsActive: Boolean,
    maxDate: {
      type: String, // Accept maxDate as a string
      default: null, // Default is no restriction
    },
    minDate: {
      type: String, // Accept maxDate as a string
      default: null, // Default is no restriction
    },
  },
  data: function () {
    return {
      menu: false,
      modal: false,
      menu2: false,
      theDate: null,
    }
  },
  mounted() {
    this.theDate = JSON.parse(JSON.stringify(this.date))
  },
  computed: {
    localDate: {
      get() {
        return this.date
      },
      set(val) {
        this.theDate = val
        this.$emit("update", val)
        return val
      },
    },
    computedMaxDate() {
      return this.maxDate ? this.maxDate : null;
    },
    computedMinDate() {
      return this.minDate ? this.minDate : null;
    },
  },
  methods: {},
}
</script>
