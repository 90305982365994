<template>
  <div>
    <v-dialog v-model="dialogIsOpened" :max-width="dialogWidth">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="azureSynchro"
          class="text-none rounded-lg"
          color="blue"
          dark
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-upload</v-icon>
          {{ $t("employees.import.import") }}
        </v-btn>
      </template>
      <v-card class="pa-12 import-card" :width="dialogWidth">
        <v-card-text v-if="parsed">
          <v-row v-if="validNewEmployees.length > 0">
            <span style="font-size: 1.1rem">
               {{ $t('employees.import.aboutToImportMessage', { count: validNewEmployees.length }) }}
            </span>
          </v-row>
          <v-row v-else>
            <h3>No new employees to upload.</h3>
          </v-row>
        </v-card-text>

        <!-- affichage des actions -->
        <v-card-actions v-if="parsed && !failure">
          <v-spacer></v-spacer>
          <v-btn
            v-if="parsed"
            @click="cancel()"
            class="mr-2 text-none rounded-lg"
            outlined
            elevation="0"
            :disabled="isLoading"
            >{{ $t("cancel") }}</v-btn
          >
          <v-dialog
            v-model="confirmationDialogIsOpened"
            :max-width="dialogWidth / 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="validNewEmployees.length > 0"
                @click="saveNewEmployees()"
                color="blue"
                class="text-none rounded-lg"
                dark
                elevation="0"
                v-bind="attrs"
                v-on="on"
                :loading="isLoading"
                >Envoyer</v-btn
              >
            </template>
          </v-dialog>
        </v-card-actions>
        <v-file-input
          v-else
          ref="fileupload"
          accept=".csv"
          outlined
          solo
          flat
          height="195px"
          prepend-icon=""
          label="Télécharger un fichier"
          @change="parseCSVFile($event)"
          hide-details="auto"
          ><template v-slot:label
            ><div
              class="d-flex flex-column align-center justify-space-between file-input-label pt-12 pb-8"
            >
              <v-icon size="2.5rem">mdi-arrow-right</v-icon>
              <div class="">{{ $t("employees.import.uploadFile") }}</div>
              <div class="text-caption">
                {{ $t("employees.import.selectFile") }}
              </div>
            </div></template
          ></v-file-input
        >
      </v-card>
    </v-dialog>

    <v-btn
      class="text-none rounded-lg ml-2"
      color="orange"
      dark
      elevation="0"
      @click="getEmployeesListCsv()"
    >
      <v-icon left>mdi-download</v-icon>
      {{ $t("employees.export.export") }}
    </v-btn>

    <v-dialog v-model="downloadDialogIsOpened" :max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon outlined small class="ml-2" v-bind="attrs" v-on="on"
          ><v-icon size="14">mdi-help</v-icon></v-btn
        ></template
      >
      <v-card class="pa-12">
        <v-card-text>{{ $t("employees.import.uploadHint") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDownloadDialog()"
            class="text-none rounded-lg"
            outlined
            light
            elevation="0"
            >{{ $t("close") }}</v-btn
          >
          <v-btn
            href="https://csg-storage-oppens.s3.eu-west-3.amazonaws.com/_store/Oppens-Load+employees-Sample-v1.csv"
            color="blue"
            class="text-none rounded-lg"
            dark
            elevation="0"
            >{{ $t("employees.import.downloadTemplate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EmployeeRepository from "@/repositories/EmployeeRepository"
import OpEmployeeComponent from "./OpEmployeeComponent.vue"
import { isEmpty } from "lodash"
import CompanyLogic from "@/logics/CompanyLogic"
import { saveAs } from "file-saver"

export default {
  name: "op-employee-component",
  props: {
    companyUuid: {
      type: String,
    },
    currentEmployees: {
      type: Array,
      required: true,
    },
    azureSynchro: {
      type: Boolean,
      required: true,
    },
  },
  inject: ["createEmployeeList"],
  data() {
    return {
      warningKeywords: [
        "support",
        "technique",
        "achat",
        "compta",
        "comptabilite",
        "secretariat",
        "accueil",
      ],
      isLoading: false,
      dialogIsOpened: false,
      downloadDialogIsOpened: false,
      confirmationDialogIsOpened: false,
      validNewEmployees: null,
      file: null,
      parsed: false,
      duplicateEmails: [],
      invalidEmails: [],
      specialStringsDetectedInEmails: [],
      errors: false,
      warnings: false,
      failure: false, // invalid CSV format
      alertMessage: "",
      confirmationMessage: "",
    }
  },
  methods: {
    async getEmployeesListCsv() {
      let res = await CompanyLogic.getEmployeeList(this.companyUuid)
      saveAs(res.data, "EmployeesList.csv")
    },

    parseCSVFile($event) {
      try {
        if (!$event) {
          return
        }
        this.alertMessage = ""
        this.file = event.target.files[0]
        this.$papa.parse(this.file, {
          delimiter: "",
          newline: "",
          quoteChar: '"',
          escapeChar: '"',
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            this.content = results
            this.parsed = true
            this.parseResults(results.data)
          }.bind(this),
        })
      } catch (e) {
        this.failure = true
      }
    },
    parseResults(data) {
      if (!data) {
        return
      }
      const parsedEmployees = []
      data.forEach((val, index) => {
        if (index === 0) {
          return
        }
        let employee = {
          informations: {
            first_name: val[0],
            last_name: val[1],
            email: val[2],
            lang: val[3],
            job: val[4],
            position: "",
            telephone: val[6],
            entity: val[7],
            department: val[8],
            city: val[9],
            country: val[10],
            manager_email: val[11],
            is_generic_email_address: val[12],
          },
        }
        parsedEmployees.push(employee)
      })

      this.validNewEmployees = parsedEmployees
    },
    async saveNewEmployees() {
      this.isLoading = true
      try {
        const list = JSON.parse(
          JSON.stringify(this.validNewEmployees.map((e) => e.informations))
        )
        await this.createEmployeeList(
          this.companyUuid,
          list,
          EmployeeRepository.ADD_MODE_STOCK
        )
        this.$emit("refresh-employees")
        this.reset()
        this.dialogIsOpened = false
      } catch (error) {
        this.reset()
        this.dialogIsOpened = false

        // this.alertMessage = error?.response?.data?.error?.message || this.$t("error.default")
      } finally {
        this.isLoading = false
      }
    },
    closeDownloadDialog() {
      this.downloadDialogIsOpened = false
    },
    cancel() {
      this.reset()
    },
    reset() {
      this.parsed = false
      this.validNewEmployees = null
      this.errors = false
      this.warnings = false
      this.file = null
      this.alertMessage = ""
      this.confirmationDialogIsOpened = false
    },
    suppressSingleEmployeeFromValidList(data) {
      if (!data.informations.email) {
        return
      }
      this.validNewEmployees = this.validNewEmployees.filter(
        (val) => val.informations.email !== data.informations.email
      )
    },
    closeConfirmationDialog() {
      this.confirmationDialogIsOpened = false
    },
  },
  computed: {
    dialogWidth() {
      if (!this.parsed) {
        return 720
      } else {
        return 1024
      }
    },
  },
  //components: { OpEmployeeComponent },
}
</script>
<style scoped>
.import-card :deep(.v-input) .v-label {
  height: 100%;
}
.import-card :deep(.v-text-field.v-text-field--solo) .v-label {
  top: 0;
}
.import-card :deep(.file-input-label) {
  width: 596px;
  height: 100%;
}
.employee-list {
  max-height: 300px;
  overflow-y: auto;
}
</style>
