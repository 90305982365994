<template>
  <div v-if="localData">
    <v-card elevation="0" outlined color="white" class="ma-1">
      <v-card-text>
        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2" color="green">
            {{ $t("view.learning_phishing.title") }}
          </h3>

          <v-text-field
            :label="$t('view.learning_phishing.title')"
            v-model="localData.title"
            class="required"
          >
          </v-text-field>
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.language") }}</h3>

          <v-select
            :label="$t('view.learning_phishing.select_a_language')"
            v-model="localData.lang"
            :items="languages"
            item-text="label"
            item-value="code"
          />
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.animation_type") }}
          </h3>
          <v-select
            :label="$t('view.learning_phishing.type')"
            v-model="localData.type"
            :items="animationTypes"
            item-value="value"
          />
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.description") }}</h3>

          <custom-editor
            v-model="localData.description"
            :value="localData.description"
            :isDecription="true"
            :selectedType="selectedType"
          />
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.is_multivalue") }}
          </h3>

          <v-checkbox
            v-model="localData.ismultivalue"
            :label="$t('view.learning_phishing.is_multivalue')"
          ></v-checkbox>
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.feedback") }}</h3>

          <v-textarea
            :label="$t('view.learning_phishing.feedback')"
            v-model="localData.feedback"
            hide-details
            single-line
          />
        </v-row>
        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.feedback_long") }}
          </h3>

          <v-textarea
            :label="$t('view.learning_phishing.feedback_long')"
            v-model="localData.feedback_long"
            hide-details
            single-line
          />
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.illustration") }}</h3>

          <v-text-field
            :label="$t('view.learning_phishing.url_illustration')"
            v-model="localData.illustration"
            hide-details
            single-line
          />
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">
            {{ $t("view.learning_phishing.html_illustration") }}
          </h3>

          <custom-editor
            v-model="localData.html_illustration"
            :value="localData.html_illustration"
            :isDescription="false"
            :selectedType="selectedType"
          />
        </v-row>

        <v-row class="mt-10">
          <h3 style="width: 100%; color: #1cc8aa" class="mb-2">{{ $t("view.learning_phishing.response") }}</h3>

          <v-data-table
            v-model="selectedAnswers"
            :headers="headers"
            :items="answers"
            :single-select="false"
            :items-per-page="10"
            item-key="id"
            show-select
            class="elevation-1"
            :search="search"
            :footer-props="footerProps"
          >
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
        >{{ $t("view.button.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{ $t("view.button.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import oppensLearningMixin from "@/mixins/oppens.learning.mixin.js"
import tableMixin from "@/mixins/answersTable.mixin"
import CustomEditor from "./CustomEditor.vue"

export default {
  components: {
    CustomEditor,
  },

  mixins: [oppensLearningMixin, tableMixin],

  name: "oppens-learning-slide-edition",

  props: {
    data: {
      default: null,
    },
    answers: {
      default: null,
    },
    languages: {
      default: null,
    },
    animationTypes: {
      default: null,
    },
  },

  data: function () {
    return {
      selectedAnswers: [],
      localData: null,
      selectedType: null,
    }
  },

  async mounted() {
    this.localData = JSON.parse(JSON.stringify(this.data))

    this.selectedAnswers = []
    for (let answer of this.localData.answers) {
      const fullAnswer = this.answers.find((elmt) => elmt.id === answer.id)
      if (fullAnswer) {
        this.selectedAnswers.push(fullAnswer)
      }
    }
    this.selectedType = this.localData.type
  },

  watch: {
    "localData.type": function (newType, oldType) {
      this.selectedType = newType
    },
  },

  computed: {
    form() {
      return this.$refs.form
    },
  },

  methods: {
    async submit() {
      this.localData.answers = this.selectedAnswers.map((elt) => elt.id)
      this.localData.type = this.selectedType
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
