<template>
  <v-container class="pa-0" style="background-color: white">
    <op-confirmation-dialog ref="confirm"/>
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-auto d-flex"
      v-if="isLoading"
    ></v-progress-circular>
    <v-row v-else>
      <v-col cols="12">
        <div width="100%" height="100%">
          <div class="d-flex align-center justify-center">
            <div class="d-flex align-center justify-space-around mr-5 my-2">
              <span
                class="mdi mdi-domain mr-5"
                style="font-size: 45px; color: grey"
              ></span>

              <div>
                <div class="name">
                  {{ company.business_name }}
                  <v-icon
                    class="ml-2"
                    elevation="2"
                    icon
                    v-if="company.verified"
                    color="#debd00"
                  >mdi-star
                  </v-icon
                  >

                  <v-icon
                    class="ml-2 mr-2"
                    elevation="2"
                    icon
                    v-if="company.is_saas"
                    color="purple"
                  >mdi-yin-yang
                  </v-icon
                  >

                  <v-icon
                    class="ml-2"
                    elevation="2"
                    icon
                    v-if="company.is_msp"
                    color="#FF9800"
                  >mdi-hexagon-multiple
                  </v-icon
                  >
                </div>
                <div class="infos">{{ company.email_address }}</div>
                <div class="infos">{{ company.field_of_activity }}</div>
              </div>
            </div>
            <div class="mx-5 my-2">
              <div class="themeTitle mt-2">
               <!-- {{ $t("humanCyberscore.overallTitle") }} -->
              </div>
              <div class="d-flex align-center justify-center">
                <div class="teal--text mr-2">
                  <span class="globalScore font-weight-bold">{{
                      `${globalScore}  `
                    }}</span>
                  <span class="font-weight-bold globalScoreHundred">/ 100</span>
                </div>
                <op-cyberscore-gauge :score="globalScore"/>
              </div>
            </div>
            <div class="ml-5">
              <op-company-cyberscore-bubble
                v-if="company.gamification"
                :gamification-info="company.gamification"
                :company="company"
              ></op-company-cyberscore-bubble>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PhishingIcon300 from "@/assets/learning/badges/badge-phishing-300px.png"
import PhishingIcon100 from "@/assets/learning/badges/badge-phishing-100px.png"
import DataProtectionIcon300 from "@/assets/learning/badges/badge-data-protection-300px.png"
import DataProtectionIcon100 from "@/assets/learning/badges/badge-data-protection-100px.png"
import WorkstationIcon300 from "@/assets/learning/badges/badge-workstation-300px.png"
import WorkstationIcon100 from "@/assets/learning/badges/badge-workstation-100px.png"
import OutOfOfficeIcon300 from "@/assets/learning/badges/badge-out-of-office-300px.png"
import OutOfOfficeIcon100 from "@/assets/learning/badges/badge-out-of-office-100px.png"
import CommunicationToolsIcon300 from "@/assets/learning/badges/badge-communication-tools-300px.png"
import CommunicationToolsIcon100 from "@/assets/learning/badges/badge-communication-tools-100px.png"
import SocialEngineeringIcon300 from "@/assets/learning/badges/badge-social-engineering-300px.png"
import SocialEngineeringIcon100 from "@/assets/learning/badges/badge-social-engineering-100px.png"

export default {
  name: "op-cyberscore-summary",
  props: {
    isLoading: {
      type: Boolean,
      default() {
        return true
      },
    },
    company: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      test: false,
      themes: [
        {
          code: "phishing",
          icons: {px100: PhishingIcon100, px300: PhishingIcon300},
        },
        {
          code: "out-of-office",
          icons: {px100: OutOfOfficeIcon100, px300: OutOfOfficeIcon300},
        },
        {
          code: "data-protection",
          icons: {px100: DataProtectionIcon100, px300: DataProtectionIcon300},
        },
        {
          code: "communication-tools",
          icons: {
            px100: CommunicationToolsIcon100,
            px300: CommunicationToolsIcon300,
          },
        },
        {
          code: "workstation",
          icons: {px100: WorkstationIcon100, px300: WorkstationIcon300},
        },
        {
          code: "social-engineering",
          icons: {
            px100: SocialEngineeringIcon100,
            px300: SocialEngineeringIcon300,
          },
        },
      ],
    }
  },

  computed: {
    globalScore() {
      return this.company?.gamification?.global_cyber_score
        ? this.company.gamification.global_cyber_score
        : 0
    },
  },
}
</script>

<style scoped>
.name {
  color: #1cc8aa;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.infos {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.themeTitle {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-align: end;
}

.globalScore {
  font-size: 16px;
}

.globalScoreHundred {
  font-size: 10px;
}
</style>
