import Request from "../services/Request"

export default class AuthRepository {
  /**
   *
   * @returns {Promise}
   */
  static me() {

    const requestParams = {
      with_statuses: "1",
      include: "roles",
    }

    return Request.make("GET", "/v1/user", { params: requestParams })
  }

  static login(body) {
    return Request.make("POST", "/login", {
      ...body,
    })
  }

  /**
   *
   * @param body
   * @returns {Promise}
   */
  static register(body) {
    return Request.make("POST", "/users", body)
  }

  /**
   * @returns {Promise}
   */
  static logout() {
    return Request.make("POST", "/signout")
  }

  /**
   *
   * @param refreshToken
   * @returns {Promise}
   */
  static refreshToken(refreshToken) {
    return Request.make("POST", "/oauth/token", {
      refresh_token: refreshToken,
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      grant_type: "refresh_token",
    })
  }
}
